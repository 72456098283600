import { Col, Row, Tag, message } from "antd";
import { POST_API, POST_CATCH, getToken, verifyConfig } from "../../../services/api";
import TableComponent from "../../../components/Table";
import { useEffect, useState } from "react";

const DashCliente = () => {

    const [ column, setColumn ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ pagination, setPagination ] = useState({ current: 1, total: 0, limit: 10 });
    const [ filter, setFilter ] = useState(null);
    const [ sorter, setSorter ] = useState(null);
    const [ load, setLoad ] = useState(true);
    const [ search, setSearch ] = useState('');
    const [ banner01, setBanner01 ] = useState('');
    const [ banner02, setBanner02 ] = useState('');

    const renderColumn = () => {
        setColumn([
            { title: 'Data Atualização', dataIndex: 'DATETIME_INSERT_FORMAT', key: 'process_comment.DATETIME_INSERT', width: 120, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
            { title: 'Processo', dataIndex: 'NUMBER', key: 'process.ID', width: 200, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
            { title: 'Parte Adversária', dataIndex: 'PROCESS_STEP_NAME', key: 'process_step.NAME', width: 180, align: 'center' },
            { title: 'Situação', dataIndex: 'PROCESS_STEP_NAME', key: 'process_step.NAME', width: 180, align: 'center' },
            { title: 'Atualização', dataIndex: 'PROCESS_STEP_NAME', key: 'process_step.NAME', width: 180, align: 'center' },
            // { title: 'Ações', key: 'action', width: 80, render: (text, record) => (
            //     <Row gutter={[4,4]} className="table__actions">

            //     </Row>
            // )},
        ])
    }

    const onRenderData = () => {
        setLoad(true)
        POST_API('/myprocess/search.php', { token: getToken(), pagination: JSON.stringify(pagination), filter: JSON.stringify(filter), sorter: JSON.stringify(sorter), search: search }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setData(res.data)
                setPagination({ ...pagination, total: res.summary.QTDE })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => setLoad(false))
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        setFilter(filters)
        setSorter(sorter)
        onRenderData()
    }

    const onPaginationChange = (page, size) => {
        setPagination({
            ...pagination,
            current: page,
            limit: size
        })
        onRenderData()
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value)
        onRenderData()
    }

    useEffect(() => {
        setTimeout(async () => {
            POST_API('/banner/search.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setBanner01(res.data.BANNER01)
                    setBanner02(res.data.BANNER02)
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
        renderColumn()
        setTimeout(() => {
            onRenderData()
        }, 500);
    }, [])

    return (
        <Row gutter={[8,8]} align={'middle'}>
            <Col md={12} xs={24}>
                <div className="dash-banner">
                    <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                        { banner01 ? <img src={banner01} className="image-banner" /> : null }
                    </Row>
                </div>
            </Col>
            <Col md={12} xs={24}>
                <div className="dash-banner">
                <Row justify="center" align={'middle'} style={{ flexDirection: 'column' }}>
                        { banner02 ? <img src={banner02} className="image-banner" /> : null }
                    </Row>
                </div>
            </Col>
            <Col span={24}>
                <TableComponent
                    _columns={column}
                    _data={data}
                    _pagination={pagination}
                    _loading={load}
                    _onChangeTable={onTableChange}
                    _onChangePagination={onPaginationChange}
                    _onChangeSearch={onSearchChange}
                    _search={search}
                />
            </Col>
        </Row>
    )

}

export default DashCliente;