import React from "react";
import { Breadcrumb, Button, Col, Layout, Modal, Row, Switch, message, Form, Avatar, Typography, Tag, Card, Input } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoArchive, IoChatboxEllipses, IoCheckmarkDone, IoLockOpen, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controller/Page403';
import ReactInputMask from "react-input-mask";

class AdminChatList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: null,
        table_sorter: null,
        table_select: [],
        modalNew: false,
        doc: false,
        cpfcnpj: '',
        newLoading: false,
        _navigate_add: false,
        dadoCliente: null
    }

    back = "/painel/chat";

    routes = [
        {title: <Link to={this.back}>Chats</Link>},
        {title: this.props.type === 'list' ? 'Lista' : 'Lixeira'}
    ]

    componentDidMount = () => {
        titleWeb('Chats')
        this.onRenderData()
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/chat/search.php', { token: getToken(), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={'/painel'} />)
        if (this.state._navigate_add) return (<Navigate to={'add'} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Input.Search onSearch={this.onRenderData} onChange={(e) => this.setState({table_search: e.target.value})} size="large" placeholder={"Pesquisar..."} id="PESQUISA"  className="table__form_filter_input" value={this.state.table_search}  />
                                    </Col>
                                    { this.state.table_load ? (
                                        <Col span={24}>
                                            <Typography>Carregando...</Typography>
                                        </Col>
                                    ) : !this.state.table_data.length ? (
                                        <Col span={24}>
                                            <Typography>Nenhum cliente encontrado para iniciar uum chat</Typography>
                                        </Col>
                                    ) : this.state.table_data.map((item) => (
                                        <Col span={24}>
                                            <Link to={`${item?.ID}`}>
                                                <Row gutter={[10,10]} align={'middle'} className="card-chat">
                                                    <Col flex={'60px'}><Avatar style={{width: '60px',height: '60px'}} src={item?.CREDENTIAL_AVATAR} /></Col>
                                                    <Col flex={'auto'}>
                                                        <Typography style={{fontWeight: 600}} className="last_message">{item?.CREDENTIAL_NAME}</Typography>
                                                        <Typography className="last_message">{item?.LAST_MESSAGE}</Typography>
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </Col>
                                    )) }
                                </Row> 
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminChatList;