import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Input, Layout, Row, Space, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, getToken, getUPLOADAPI, titleWeb, setPath, POST_CATCH, verifyConfig } from "../../../services/api";

// CONTROLLER
import Page403 from '../../Controller/Page403';
import PageLoad from '../../Controller/PageLoad';
import { IoAttachOutline, IoCloudDownloadOutline, IoSend } from "react-icons/io5";


class AdminChatForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _dados: null,
        _modal: false,
        _params: window.location.href.split('/'),
        tags: [],
        vdoc: false,
        defaultValue: {ID: 0},
        value: '',
        value_send: false,
        archive_send: false,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: { current: 1, total: 0, limit: 10 },
        table_filter: { },
        table_sorter: null,
        table_select: [],
        routes: [
            {title: <Link to={this.back}>Chats</Link>},
        ]
    }

    back = "/painel/chat";

    message = null;

    componentDidMount = () => {
        titleWeb('Chat')
        setPath('chat')
        this.onView()
        this.onMessage()
        this.message = setInterval(() => {
            this.onMessage()
        }, 2000);
        setTimeout(() => {
            var objDiv = document.getElementById("content-message-body");
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 1000);
    }

    onView = async () => {
        await POST_API('/chat/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            this.setState({defaultValue: res.data, _screen: false})
        }).catch(() => POST_CATCH())
    }
    onMessage = async () => {
        await POST_API('/chat/search_message.php', { token: getToken(), filter: JSON.stringify({CLIENT_ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            this.setState({table_data: res.data})
        }).catch(() => POST_CATCH())
    }

    onSend = () => {
        this.setState({value_send: true})
        POST_API('/chat/send_message.php', { token: getToken(), master: JSON.stringify({ CLIENT_ID: this.state._params[5], CHAT: this.state.value }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({value: ''})
                setTimeout(() => {
                    var objDiv = document.getElementById("content-message-body");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 1000);
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({value_send: false}))
    }

    onPic = (value) => {
        if (value.file.response?.url) {
            this.setState({archive_send: true})
            POST_API('/chat/send_message.php', { token: getToken(), master: JSON.stringify({ CLIENT_ID: this.state._params[5], ARCHIVE: value.file.response?.url }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setTimeout(() => {
                        var objDiv = document.getElementById("content-message-body");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }, 1000);
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({archive_send: false}))
        }
    }

    componentWillUnmount() {
        clearInterval(this.message);
    }

    render = () => {

        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]}>
                        <Col span={18}>
                            <Breadcrumb items={this.state.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link to={'..'}><Button type="primary" size="small" shape="round">Voltar</Button></Link>
                        </Col>
                        { this.state._screen ? <PageLoad /> : (
                            <Col span={24}>
                                <Card className="content-message" title={<Row gutter={[8,8]} align={'middle'} style={{flexWrap: "nowrap"}}><Col><Avatar src={this.state.defaultValue?.CREDENTIAL_AVATAR} /></Col><Col>{this.state.defaultValue?.CREDENTIAL_NAME}</Col></Row>}>
                                    <div className="content-message-body" id="content-message-body">
                                        <Row gutter={[8,8]}>
                                            { this.state.table_data.map((item) => (
                                                <Col span={24}>
                                                    <div className={`content-message-body-${item.TYPE}`}>
                                                        { item.ARCHIVE ? <a className="btn-download" rel="noreferrer" href={item.ARCHIVE} target="_blank" download={true}><IoCloudDownloadOutline size={30} />Baixar arquivo</a>
                                                        : <Typography>{item.CHAT}</Typography> }
                                                        <Typography className="content-message-body-read">{item.DATE_FORMAT}</Typography>
                                                    </div>
                                                </Col>
                                            )) }
                                        </Row>
                                    </div>
                                </Card>
                                <Row style={{marginTop: 10}}>
                                    <Col flex={'auto'}>
                                        <Space.Compact style={{ width: '100%' }}>
                                            <Upload maxCount={1} showUploadList={false} action={getUPLOADAPI} onChange={this.onPic}>
                                                <Button size="large" type="default" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><IoAttachOutline size={20}/></Button>
                                            </Upload>
                                            <Input onKeyDown={(event) => event.key === "Enter" ? this.onSend() : null } value={this.state.value} onChange={(v) => this.setState({value: v.target.value})} size="large" placeholder="Escreva sua mensagem aqui" />
                                            <Button onClick={this.onSend} loading={this.state.value_send} size="large" type="primary" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><IoSend size={16}/></Button>
                                        </Space.Compact>
                                    </Col>
                                </Row>
                            </Col>
                        ) }
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminChatForm;